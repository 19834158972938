import React, { useCallback } from 'react'
// @ts-ignore implicit-any
import ModalVideo from 'react-modal-video'
import { useMedia } from 'react-use'
import styled, { useTheme } from 'styled-components'

import { NextModal } from '../../../components/modals/NextModal'
import {
  modalAnimationDuration,
  videoModalBodyOpenClassName,
  videoModalPortalClassName,
} from '../../styles/constants'
import { videoModalStyles } from '../../styles/shared'

const ModalVideoWrapper = styled.div`
  .modal-video,
  .modal-video-movie-wrap iframe {
    background-color: #000;
  }

  .modal-video-body {
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: none;
  }

  .modal-video-movie-wrap {
    position: static;
  }

  .modal-video-close-btn {
    right: 24px;
    top: 24px;
    height: 24px;
    width: 24px;
  }

  .modal-video-close-btn::before,
  .modal-video-close-btn::after {
    margin-top: 0;
  }

  @media (max-width: 769px) {
    .modal-video-movie-wrap {
      height: 74vh
    }
`

export const VideoModal: React.FC<{
  isOpen: boolean
  onClose(): void
  videoPlatform: string | null
  videoId: string | null
  videoIdMobile?: string | null
}> = ({ isOpen, onClose, videoPlatform, videoId, videoIdMobile }) => {
  const { device } = useTheme()
  const isDesktop = useMedia(`(min-width: ${device.breakpoint.md})`, false)
  const onVideoAreaKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === ' ' || event.key === 'Enter') {
        if (
          event.target instanceof HTMLElement &&
          event.target.getAttribute('class')?.includes('modal-video-close-btn')
        ) {
          event.preventDefault()
          onClose()
        }
      }
    },
    [onClose]
  )

  const getVideoId = () => {
    if (videoIdMobile && !isDesktop) {
      return videoIdMobile
    }
    return videoId
  }

  return (
    <NextModal
      isOpen={isOpen}
      bodyOpenClassName={videoModalBodyOpenClassName}
      closeTimeoutMS={modalAnimationDuration}
      onRequestClose={onClose}
      portalClassName={videoModalPortalClassName}
      style={videoModalStyles}
    >
      <ModalVideoWrapper onKeyDownCapture={onVideoAreaKeyDown}>
        <ModalVideo
          isOpen={isOpen}
          onClose={onClose}
          videoId={getVideoId()}
          channel={videoPlatform}
          allowFullScreen
          autoplay
        />
      </ModalVideoWrapper>
    </NextModal>
  )
}
