import { NextLink } from '../../../app/_components/navigation/NextLink'

import { useMaybeSpaLink } from './useMaybeSpaLink'

type MaybeSpaLinkWrapperComponent = React.FC<{ href: string; children?: React.ReactNode }>

export const MaybeSpaLinkWrapper: MaybeSpaLinkWrapperComponent = ({ href, children }) => {
  const { isSpaLink } = useMaybeSpaLink(href)

  if (isSpaLink) {
    return (
      <NextLink
        href={href}
        data-is-spa-link={`${isSpaLink}`}
        data-testid='spa-anchor-wrapper'
        legacyBehavior
        style={{ display: 'contents' }}
      >
        {children}
      </NextLink>
    )
  } else {
    return (
      <span
        data-is-spa-link={`${isSpaLink}`}
        data-testid='spa-anchor-wrapper'
        style={{ display: 'contents' }}
      >
        {children}
      </span>
    )
  }
}
